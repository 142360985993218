<template>
  <div>
    <app-loading v-if="loading"/>
    <v-container v-if="!loading">
      <new-move
        :income="true"
        v-if="showNewMoveModal"
      />
      <edit-move v-if="showEditMoveModal"/>
      <v-card>
        <v-card-text>
          <v-row>
            <v-col
              class="py-0"
              cols="12"
            >
              <app-data-table
                :title="$vuetify.lang.t('$vuetify.titles.list', [
                    $vuetify.lang.t('$vuetify.menu.account_income')
                  ]) +'(' + parseFloat(cantIncome).toFixed(2)  + ' ' + user.company.currency +')'
                "
                :routerGoBack="'accounting_account'"
                csv-filename="Categories"
                :headers="getTableColumns"
                :items="incomes"
                :options="vBindOption"
                :manager="'accounting_account'"
                :sort-by="['firstName']"
                :sort-desc="[false, true]"
                :viewShowFilterDate="true"
                :viewShowFilterShop="true"
                :localShops="shops"
                multi-sort
                @create-row="toogleNewMovesModal(true)"
                @edit-row="editMoveHandler($event)"
                @reload-data="reloadData($event)"
                @delete-row="deleteMoveHandler($event)"
              >
                <template v-slot:item.credit="{ item }">
                  {{ `${parseFloat(item.credit).toFixed(2) + " " + user.company.currency}` }}
                </template>
                <template v-slot:item.account.name="{ item }">
                  <p>{{ `${item.account.name.includes('income_tax') ?
                    item.account.name.replace('income_tax', $vuetify.lang.t('$vuetify.accounting_category.income_tax')) :
                    item.account.name.includes('income_credit') ? item.account.name.replace('income_credit', $vuetify.lang.t('$vuetify.accounting_category.income_credit')) :
                    item.account.name.includes('incomes') ? item.account.name.replace('incomes', $vuetify.lang.t('$vuetify.accounting_category.incomes')) : item.account.name }` }}<br>
                    <b>{{ $vuetify.lang.t('$vuetify.boxes.current') + ': ' + parseFloat(item.account.current_balance).toFixed(2) + " " + user.company.currency }}</b>
                  </p>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                  <td
                    :colspan="headers.length"
                    style="padding: 0 0 0 0"
                  >
                    <template v-if="item.refund">
                      <div>
                        <p style="margin-left: 10px">{{
                            $vuetify.lang.t('$vuetify.messages.refund_detail', [item.refund.sale_article_shops.sale.no_facture], [item.refund.sale_article_shops.article_shops.name])
                          }}
                          <v-row>
                            <v-col md="4" cols="12">{{
                                $vuetify.lang.t('$vuetify.date') + ': ' + new Date(item.created_at).toLocaleDateString() + ' ' + new Date(item.created_at).toLocaleTimeString()
                              }}
                            </v-col>
                            <v-col md="4" cols="12">
                              {{ $vuetify.lang.t('$vuetify.sale.cant_money') + ': ' + parseFloat(item.refund.money).toFixed(2) + ' ' + user.company.currency }}
                            </v-col>
                            <v-col md="4" cols="12">{{
                                $vuetify.lang.t('$vuetify.sale.cant_article') + ': ' + parseFloat(item.refund.cant).toFixed(2)
                              }}
                            </v-col>
                          </v-row>
                        </p>

                      </div>
                    </template>
                    <template v-if="item.sale">
                      <div v-if="item.detail === 'sale_detail'">
                        <p style="margin-left: 10px">
                          {{ $vuetify.lang.t('$vuetify.messages.sale_detail', [item.sale.no_facture]) }}
                          <v-row>
                            <v-col md="4" cols="12"><b>{{ $vuetify.lang.t('$vuetify.date') + ': ' }} </b> {{
                                new Date(item.created_at).toLocaleDateString() + ' ' + new Date(item.created_at).toLocaleTimeString()
                              }}
                            </v-col>
                            <v-col md="4" cols="12"><b>{{ $vuetify.lang.t('$vuetify.sale.cant_money') + ': ' }}</b>
                              {{ parseFloat(item.sale.totalPrice).toFixed(2) + ' ' + user.company.currency }}
                            </v-col>
                          </v-row>
                        </p>
                      </div>
                      <div v-else-if="item.detail === 'sale_tax_detail'">
                        <p style="margin-left: 10px">
                          {{ $vuetify.lang.t('$vuetify.messages.sale_tax_detail',[item.tax.name],  [item.sale.no_facture]) }}
                          <v-row>
                            <v-col md="4" cols="12"><b>{{ $vuetify.lang.t('$vuetify.date') + ': ' }} </b> {{
                                new Date(item.created_at).toLocaleDateString() + ' ' + new Date(item.created_at).toLocaleTimeString()
                              }}
                            </v-col>
                            <v-col md="4" cols="12"><b>{{ $vuetify.lang.t('$vuetify.pay.total_pay') }}</b>
                              {{ parseFloat(item.sale.totalPay).toFixed(2) + ' ' + user.company.currency }}
                            </v-col>
                            <v-col md="4" cols="12"><b>{{ $vuetify.lang.t('$vuetify.sale.cant_money') + ': ' }}</b>
                              {{ parseFloat(item.sale.totalPrice).toFixed(2) + ' ' + user.company.currency }}
                            </v-col>
                          </v-row>
                        </p>
                      </div>
                      <div v-else-if="item.detail === 'sale_article_tax_detail'">
                        <p style="margin-left: 10px">
                          {{ $vuetify.lang.t('$vuetify.messages.sale_article_tax_detail',[item.tax.name],  [item.sale.no_facture]) }}
                          <v-row>
                            <v-col md="4" cols="12"><b>{{ $vuetify.lang.t('$vuetify.date') + ': ' }} </b> {{
                                new Date(item.created_at).toLocaleDateString() + ' ' + new Date(item.created_at).toLocaleTimeString()
                              }}
                            </v-col>
                            <v-col md="4" cols="12"><b>{{ $vuetify.lang.t('$vuetify.pay.total_pay') }}</b>
                              {{ parseFloat(item.sale.totalPay).toFixed(2) + ' ' + user.company.currency }}
                            </v-col>
                            <v-col md="4" cols="12"><b>{{ $vuetify.lang.t('$vuetify.sale.cant_money') + ': ' }}</b>
                              {{ parseFloat(item.sale.totalPrice).toFixed(2) + ' ' + user.company.currency }}
                            </v-col>
                          </v-row>
                        </p>
                      </div>
                      <div v-else>
                        <p style="margin-left: 10px">
                          {{ $vuetify.lang.t('$vuetify.messages.pay_detail',  [item.sale.no_facture]) }}
                          <v-row>
                            <v-col md="4" cols="12"><b>{{ $vuetify.lang.t('$vuetify.date') + ': ' }} </b> {{
                                new Date(item.created_at).toLocaleDateString() + ' ' + new Date(item.created_at).toLocaleTimeString()
                              }}
                            </v-col>
                            <v-col md="4" cols="12"><b>{{ $vuetify.lang.t('$vuetify.pay.total_pay') }}</b>
                              {{ parseFloat(item.sale.totalPay).toFixed(2) + ' ' + user.company.currency }}
                            </v-col>
                            <v-col md="4" cols="12"><b>{{ $vuetify.lang.t('$vuetify.sale.cant_money') + ': ' }}</b>
                              {{ parseFloat(item.sale.totalPrice).toFixed(2) + ' ' + user.company.currency }}
                            </v-col>
                          </v-row>
                        </p>
                      </div>
                    </template>
                    <template v-if="!item.refund && !item.sale">
                      <p style="margin-left: 10px">
                        {{ item.detail }}
                      </p>
                    </template>
                  </td>
                </template>
              </app-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import NewMove from '../../accounting-move/commerce/NewMove'
import EditMove from '../../accounting-move/commerce/EditMove'

export default {
  name: 'OthersIncomes',
  components: { EditMove, NewMove },
  computed: {
    ...mapState('accountMove', ['showNewMoveModal', 'showEditMoveModal', 'incomes', 'isTableLoading', 'cantIncome']),
    ...mapState('account', ['editAccount']),
    ...mapGetters('auth', ['user']),
    ...mapState('shop', ['shops']),
    getTableColumns () {
      return [
        {
          text: this.$vuetify.lang.t('$vuetify.menu.shop'),
          value: 'shop.name',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.accounting_category.accounting_accounts'),
          value: 'account.name',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.date'),
          value: 'date',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.accounting_category.amount'),
          value: 'credit',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.actions.actions'),
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  data () {
    return {
      vBindOption: {
        itemKey: 'id',
        singleExpand: false,
        showExpand: true
      },
      loading: false
    }
  },
  async created () {
    this.loading = true
    await this.getShops()
    this.loading = false
  },
  methods: {
    ...mapActions('accountMove', ['getIncomes', 'toogleNewMovesModal', 'openEditMovesModal', 'deleteMove']),
    ...mapActions('account', ['getEditAccount']),
    ...mapActions('shop', ['getShops']),
    editMoveHandler ($id) {
      this.openEditMovesModal($id)
    },
    deleteMoveHandler ($id) {
      this.$Swal
        .fire({
          title: this.$vuetify.lang.t('$vuetify.titles.delete', [
            this.$vuetify.lang.t('$vuetify.menu.account_move')
          ]),
          text: this.$vuetify.lang.t(
            '$vuetify.messages.warning_delete'
          ),
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: this.$vuetify.lang.t(
            '$vuetify.actions.cancel'
          ),
          confirmButtonText: this.$vuetify.lang.t(
            '$vuetify.actions.delete'
          ),
          confirmButtonColor: 'red'
        })
        .then(result => {
          if (result.value) {
            this.deleteMove($id).then(() => {
              this.getEditAccount(this.$route.params.account).then((ac) => {
                this.editAccount = ac
              })
              this.getMovesByAccount(this.$route.params)
            })
          }
        })
    },
    reloadData (filter) {
      this.loadInitData(filter)
    },
    async loadInitData (filter) {
      const shopsId = []
      filter[2].forEach((v) => {
        shopsId.push(v.id)
      })
      const filterLocal = [this.$moment(filter[0]).format('YYYY-MM-DD H:m:s'),
        this.$moment(filter[1]).format('YYYY-MM-DD H:m:s'),
        shopsId]
      await this.getIncomes(filterLocal)
      // this.loadingData = false
    }
  }
}
</script>

<style scoped>

</style>
