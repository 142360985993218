import { render, staticRenderFns } from "./EditMove.vue?vue&type=template&id=d93bcaf4&scoped=true&"
import script from "./EditMove.vue?vue&type=script&lang=js&"
export * from "./EditMove.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d93bcaf4",
  null
  
)

export default component.exports